import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getSortedPosts } from './posts';
import { BlogContainer, PostList, PostCard, PostListTitle, PostExcerpt, PostDate, MainTitle, SubTitle } from './BlogStyles';

const BlogMain = () => {
    const posts = getSortedPosts();
    const navigate = useNavigate();
  
    const handlePostClick = (postId) => {
      navigate(postId);
    };

    const handleTitleClick = () => {
        navigate('/blog');
      };
  
    return (
      <>
        <Helmet>
          <title>몽픽 블로그 - 꿈과 현실의 이야기 | 몽픽(MongPick)</title>
          <meta name="description" content="몽픽 블로그에서 꿈해몽과 관련된 다양한 이야기를 만나보세요." />
          <meta name="keywords" content="몽픽, 꿈해몽, 꿈 해석, 로또번호, 꿈 분석" />
          <link rel="canonical" href="https://mongpick.com/blog" />
          
          {/* Open Graph 태그 추가 */}
          <meta property="og:title" content="몽픽 블로그 - 꿈과 현실의 이야기" />
          <meta property="og:description" content="몽픽 블로그에서 꿈해몽과 관련된 다양한 이야기를 만나보세요." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://mongpick.com/blog" />
        </Helmet>

        <MainTitle onClick={handleTitleClick}>몽픽 블로그</MainTitle>
        <BlogContainer>
          <SubTitle>블로그 목록</SubTitle>
          <PostList>
            {posts.map(post => (
              <div 
                key={post.id}
                onClick={() => handlePostClick(post.id)}
                style={{ cursor: 'pointer' }}
              >
                <PostCard>
                  <PostListTitle>{post.title}</PostListTitle>
                  <PostExcerpt>{post.excerpt}</PostExcerpt>
                  <PostDate>{post.date}</PostDate>
                </PostCard>
              </div>
            ))}
          </PostList>
        </BlogContainer>
      </>
    );
  };

export default BlogMain;