import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { ChevronUp } from 'lucide-react'; // lucide-react에서 위쪽 화살표 아이콘 import
import { MainTitle, ScrollTopButton } from './BlogStyles';
import { blogPosts } from './posts';

const BlogPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const post = blogPosts[postId];
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleTitleClick = () => {
    navigate('/blog');
  };

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  const PostComponent = post.component;

  return (
    <>
      <MainTitle onClick={handleTitleClick}>몽픽 블로그</MainTitle>
        <PostComponent />
        <ScrollTopButton 
          onClick={scrollToTop}
          visible={showScroll}
          aria-label="Scroll to top"
        >
          <ChevronUp size={24} />
        </ScrollTopButton>
    </>
  );
};

export default BlogPost;