const API_BASE_URL = process.env.REACT_APP_API_URL;

export const dreamApiService = {
  interpretDream: async (dream) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dream/interpret`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dream }),
      });

      if (!response.ok) {
        throw new Error('서버 오류입니다. 새로고침 후 다시 시도 바랍니다.');
      }

      return await response.json();
    } catch (error) {
      console.error('Dream interpretation error:', error);
      throw error;
    }
  },

  saveDream: async (dreamData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dream/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dreamData),
      });

      if (!response.ok) {
        throw new Error('저장 중 오류가 발생했습니다.');
      }

      return await response.json();
    } catch (error) {
      console.error('Dream save error:', error);
      throw error;
    }
  },
};