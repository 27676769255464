import React from 'react';
import styled from 'styled-components';
import CoupangAd from './CoupangAd';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const ModalContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 550px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h3`
  margin: 0 0 15px 0;
  color: #333;
  font-size: 20px;
`;

const ModalText = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const ConfirmButton = styled.button`
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const AdModalPop = ({ onClose }) => {
  const coupangPartnerLinks = [
    "https://link.coupang.com/a/bXKgHt", //전기장판
    "https://link.coupang.com/a/bXKg3c", //폴리오 마사지
    "https://link.coupang.com/a/bXKhic", //카카오 충전 손난로
    "https://link.coupang.com/a/bXKhB5", //근적외선 좌욕기
    "https://link.coupang.com/a/bXOa1D"  //공기청정기
  ];

  const checkShouldShowAd = () => {
    const lastClickTime = localStorage.getItem('lastCoupangClickTime');
    if (!lastClickTime) return true;

    const currentTime = new Date().getTime();
    const sixHours = 10 * 60 * 1000;
    return (currentTime - parseInt(lastClickTime)) >= sixHours;
  };

  const handleContinue = () => {
    if (checkShouldShowAd()) {
      const randomLink = coupangPartnerLinks[Math.floor(Math.random() * coupangPartnerLinks.length)];
      window.open(randomLink, '_blank');
      localStorage.setItem('lastCoupangClickTime', new Date().getTime().toString());
    }
    onClose();
  };

  const getButtonText = () => {
    return checkShouldShowAd() 
      ? "상품 추천 받고 결과 보기" 
      : "바로 이어보기";
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>잠시만 기다려주세요</ModalTitle>
        <ModalText>
          {checkShouldShowAd() 
            ? "광고 시청 후 이어서 확인 가능합니다." 
            : "결과를 바로 확인하실 수 있습니다."}
        </ModalText>
        <CoupangAd />
        <ConfirmButton onClick={handleContinue}>
          {getButtonText()}
        </ConfirmButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AdModalPop;