import styled from 'styled-components';
import { CommonContainer } from './CommonStyles';

export const FormContainer = styled(CommonContainer).attrs({ as: 'form' })`
  justify-content: space-between;
`;

export const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledTextarea = styled.textarea`
  width: 96%;
  height: 280px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px; // CharacterCount를 위한 공간 확보

  @media (max-width: 768px) {
    width: 95%;
    height: 230px;
    font-size: 18px;
  }
`;

export const CharacterCount = styled.span`
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #666;
  font-size: 14px;
  background: transparent;

  @media (max-width: 768px) {
    right: 25px;
    font-size: 12px;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;