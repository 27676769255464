import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const ModalContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 550px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h3`
  margin: 0 0 15px 0;
  color: #333;
  font-size: 20px;
`;

const UrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  width: 100%;
`;

const UrlBox = styled.div`
  position: relative;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  word-break: break-all;
  cursor: text;
  text-align: left;
  min-height: 50px;
  overflow-x: auto;
  user-select: all;
  font-size: 14px;
`;

const Message = styled.p`
  color: #28a745;
  margin: 10px 0;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  
  &:active {
    transform: scale(0.98);
  }
`;

const CopyButton = styled(Button)`
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;

const CloseButton = styled(Button)`
  background-color: #6c757d;
  color: white;

  &:hover {
    background-color: #545b62;
  }
`;

const ShareModal = ({ shareUrl, onClose }) => {
  const [copied, setCopied] = useState(false);
  const urlRef = useRef(null);

  const copyToClipboard = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (err) {
          alert('URL을 길게 눌러서 복사해주세요.');
        }
        document.body.removeChild(textArea);
      }
    } catch (err) {
      alert('URL을 길게 눌러서 복사해주세요.');
    }
  };

  const handleUrlClick = () => {
    if (urlRef.current) {
      const range = document.createRange();
      range.selectNodeContents(urlRef.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <ModalOverlay onClick={e => e.target === e.currentTarget && onClose()}>
      <ModalContent>
        <ModalTitle>결과가 저장되었습니다</ModalTitle>
        <p>아래 링크를 복사하여 공유하세요</p>
        <UrlContainer>
          <UrlBox ref={urlRef} onClick={handleUrlClick}>
            {shareUrl}
          </UrlBox>
        </UrlContainer>
        <Message show={copied}>✓ 클립보드에 복사되었습니다</Message>
        <ButtonGroup>
          <CopyButton onClick={copyToClipboard}>
            복사하기
          </CopyButton>
          <CloseButton onClick={onClose}>
            닫기
          </CloseButton>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ShareModal;