import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CommonContainer } from './CommonStyles';
import AdModalPop from './AdModalPop';

const ResultContainer = styled(CommonContainer).attrs({ as: 'div' })`
  justify-content: space-between;
  height: 100%; // 전체 높이 사용
  overflow: hidden; // 내부 스크롤을 위해 오버플로우 숨김
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const TextContainer = styled.div`
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  height: 300px; // 원하는 고정 높이 설정
  overflow-y: auto; // 고정 높이를 넘어가면 스크롤 생성

  /* 스크롤바 스타일링 */
  ::-webkit-scrollbar {
    width: 12px; /* 스크롤바 너비 */
  }

  ::-webkit-scrollbar-track {
    background: #ffffff; /* 스크롤바 트랙 색상 */
    border-radius: 10px; /* 트랙의 라운드 모양 */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ced4da; /* 스크롤바 색상 (회색빛) */
    border-radius: 10px; /* 스크롤바의 라운드 모양 */
    border: 3px solid #ffffff; /* 스크롤바의 내부 공간을 흰색으로 */
  }
`;

const ResultText = styled.p`
  font-size: 18px;
  font-weight: bold;
  white-space: pre-wrap;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
  min-width: 200px;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.5s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomeButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const NumbersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const LotteryBall = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  margin: 5px;
  font-size: 20px; // 텍스트 크기 증가
  background-color: ${props => {
    if (props.number <= 10) return '#fbc400';
    if (props.number <= 20) return '#69c8f2';
    if (props.number <= 30) return '#ff7272';
    if (props.number <= 40) return '#aaa';
    return '#b0d840';
  }};
`;

const InlineButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  min-width: 0;
`;

const WishText = styled.p`
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
`;

const LotteryButton = styled.a`
  display: block;
  width: 90%;
  padding: 10px;
  background-color: white; /* 배경을 하얗게 */
  color: #28a745; /* 글씨를 초록색으로 */
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  margin: 10px auto 0 auto;
  border: 2px solid #28a745; /* 초록색 테두리 추가 */

  &:hover {
    background-color: #28a745; /* 배경을 초록색으로 변경 */
    color: white; /* 글씨를 하얗게 변경 */
  }
`;

const ShareButton = styled(Button)`
  background-color: #ff9800;

  &:hover {
    background-color: #f57c00;
  }
`;

const DreamResult = ({ interpretation, numberAnalysis, numbers, onReturnHome, onSaveAndShare, showSaveButton = true, homeButtonText = "처음으로 돌아가기" }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [displayedNumberAnalysis, setDisplayedNumberAnalysis] = useState('');
  const [sentences, setSentences] = useState([]);
  const [numberAnalysisSentences, setNumberAnalysisSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [showNumberButton, setShowNumberButton] = useState(false);
  const [showNumbers, setShowNumbers] = useState(false);
  const [showLotteryBalls, setShowLotteryBalls] = useState(false);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const textContainerRef = useRef(null);

  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
    }
  }, [displayedText, displayedNumberAnalysis, showLotteryBalls, showAdditionalContent, showNumberButton]);

  useEffect(() => {
    if (showNumberButton && textContainerRef.current) {
      setTimeout(() => {
        textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
      }, 100); // 약간의 지연을 줘서 버튼이 렌더링된 후 스크롤되도록 함
    }
  }, [showNumberButton]);

  useEffect(() => {
    const splitSentences = interpretation ? interpretation.match(/[^.!?]+[.!?]+/g) || [] : [];
    setSentences(splitSentences.map(sentence => sentence.trim()));
  }, [interpretation]);

  useEffect(() => {
    if (numberAnalysis) {
      const splitSentences = numberAnalysis.match(/[^.!?]+[.!?]+/g) || [];
      setNumberAnalysisSentences(splitSentences.map(sentence => sentence.trim()));
    }
  }, [numberAnalysis]);

  useEffect(() => {
    if (currentSentenceIndex < sentences.length) {
      if (currentCharIndex < sentences[currentSentenceIndex].length) {
        const timer = setTimeout(() => {
          setDisplayedText(prev => prev + sentences[currentSentenceIndex][currentCharIndex]);
          setCurrentCharIndex(prevIndex => prevIndex + 1);
        }, 50);
  
        return () => clearTimeout(timer);
      } else {
        if (currentSentenceIndex < sentences.length - 1) {
          setDisplayedText(prev => prev + '\n\n');
        }
        setCurrentSentenceIndex(prevIndex => prevIndex + 1);
        setCurrentCharIndex(0);
      }
    } else if (!showNumberButton) {
      const buttonTimer = setTimeout(() => {
        setShowNumberButton(true);
      }, 500);
  
      return () => clearTimeout(buttonTimer);
    }
  }, [sentences, currentSentenceIndex, currentCharIndex, showNumberButton]);

  // const handleShowNumbers = () => {
  //   setShowNumbers(true);
  //   displayNumberAnalysis();
  // };

  const handleShowNumbers = () => {
    setShowAdModal(true); // 광고 모달 표시
  };

  const handleAdClose = () => {
    setShowAdModal(false); // 광고 모달 닫기
    setShowNumbers(true); // 번호 표시 시작
    displayNumberAnalysis(); // 번호 분석 표시 시작
  };

  const displayNumberAnalysis = () => {
    let index = -1;
    let sentenceIndex = 0;
  
    const timer = setInterval(() => {
      if (sentenceIndex < numberAnalysisSentences.length) {
        if (index < numberAnalysisSentences[sentenceIndex].length) {
          const char = numberAnalysisSentences[sentenceIndex][index];
          if (char !== undefined) {
            setDisplayedNumberAnalysis(prev => prev + char);
          }
          index++;
        } else {
          setDisplayedNumberAnalysis(prev => prev + '\n\n');
          sentenceIndex++;
          index = -1;
        }
      } else {
        clearInterval(timer);
        setShowLotteryBalls(true);
        displayLotteryNumbers(); // 수정된 부분: 번호 분석이 끝나면 로또 번호 표시 시작
      }
    }, 100);
  };

  const displayLotteryNumbers = () => {
    setShowLotteryBalls(true);
    setTimeout(() => setShowAdditionalContent(true), 1000);
  };

  return (
    <ResultContainer>
      {showAdModal && <AdModalPop onClose={handleAdClose} />}
      <ScrollableContent>
        <TextContainer ref={textContainerRef}>
          <ResultText>{displayedText}</ResultText>
          {showNumberButton && !showNumbers && (
            <InlineButton onClick={handleShowNumbers} visible={true}>
              꿈에서 찾은 번호는?
            </InlineButton>
          )}
          {showNumbers && (
            <>
              <ResultText>{displayedNumberAnalysis}</ResultText>
              {showLotteryBalls && (
                <NumbersContainer>
                  {numbers.map((number, index) => (
                    <LotteryBall key={index} number={number}>
                      {number}
                    </LotteryBall>
                  ))}
                </NumbersContainer>
              )}
            </>
          )}
          {showAdditionalContent && (
            <>
              <WishText>모든 꿈에는 값진 의미와 특별한 번호가 있습니다.</WishText>
              <WishText>이 번호들로 좋은 결과와 좋은 일이 가득하길 기원합니다.</WishText>
              <LotteryButton href="https://dhlottery.co.kr/gameInfo.do?method=buyLotto" target="_blank" rel="noopener noreferrer">
                동행복권 온라인 구매 바로가기
              </LotteryButton>
            </>
          )}
        </TextContainer>
      </ScrollableContent>
      <ButtonContainer>
        {showSaveButton && onSaveAndShare && (
          <ShareButton onClick={onSaveAndShare} visible={true}>결과 저장 및 공유</ShareButton>
        )}
        <HomeButton onClick={onReturnHome} visible={true}>{homeButtonText}</HomeButton>
      </ButtonContainer>
    </ResultContainer>
  );
};

export default DreamResult;