import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  padding: 10px 0;
  background-color: #E6E6FA;
  color: #666;
  text-align: center;
  font-size: 14px;
  position: fixed; /* 고정형 푸터 */
  bottom: 0; /* 화면의 하단에 위치 */
  left: 0;
  z-index: 100;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
`;

const Footer = () => {
  return (
    <FooterContainer>
      &copy; {new Date().getFullYear()} 몽픽. All rights reserved.
    </FooterContainer>
  );
};

export default Footer;
