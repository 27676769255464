import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DreamResult from './DreamResult';
import { Title, Subtitle } from '../AppStyles';

const DreamView = () => {
  const { dreamId } = useParams();
  const [dreamData, setDreamData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDreamData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dream/${dreamId}`);
        if (!response.ok) {
          throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
        }
        const data = await response.json();
        setDreamData(data);
      } catch (err) {
        setError(err.message || '서버 오류가 발생했습니다.');
      }
    };

    fetchDreamData();
  }, [dreamId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!dreamData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Title>몽픽 MongPick</Title>
      <Subtitle>당신의 꿈에서 특별한 의미와 번호를 'Pick'하세요.</Subtitle>
      <DreamResult
        interpretation={dreamData.dream_analysis}
        numberAnalysis={dreamData.number_analysis}
        numbers={dreamData.numbers}
        showSaveButton={false}
        homeButtonText="나만의 꿈 해석 'Pick' 하러가기"
        onReturnHome={() => window.location.href = "/"} 
      />
    </>
  );
};

export default DreamView;
