import React, { useState } from 'react';
import { 
  FormContainer, 
  StyledTextarea, 
  SubmitButton, 
  TextareaWrapper,
  CharacterCount 
} from './DreamFormStyles';

function DreamForm({ onSubmit }) {
  const [dream, setDream] = useState('');
  const maxLength = 400;

  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length > maxLength) {
      alert('400자 미만으로 작성해주세요');
      setDream(text.slice(0, maxLength));
    } else {
      setDream(text);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(dream);
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <TextareaWrapper>
        <StyledTextarea
          value={dream}
          onChange={handleChange}
          placeholder="어떤 꿈을 꾸셨나요?"
          maxLength={maxLength}
        />
        <CharacterCount>{dream.length}/{maxLength}</CharacterCount>
      </TextareaWrapper>
      <SubmitButton type="submit">제출</SubmitButton>
    </FormContainer>
  );
}

export default DreamForm;