import React from 'react';
import styled, { keyframes } from 'styled-components';
import Footer from './Footer';

const slideLeft = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const slideRight = keyframes`
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0); }
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-color: #E6E6FA;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  opacity: 0.2;
`;

const DreamRow = styled.div`
  display: flex;
  width: 200%;
  animation: ${props => props.reverse ? slideRight : slideLeft} 60s linear infinite;
  margin-bottom: 20px;
`;

const DreamBox = styled.div`
  flex: 0 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 10px;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 150px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 250px;
    height: 120px;
    font-size: 14px;
    padding: 15px 20px;
  }
`;

const DreamTitle = styled.strong`
  font-size: 18px;
  margin-bottom: 8px;
  display: block;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 4%;
  left: calc(50% + 310px); // 중앙에서 오른쪽으로 100px 이동
  transform: translateX(-50%);
  z-index: 1;
  width: 400px;

  @media (max-width: 768px) {
    width: 300px;
    left: calc(50% + 27vh); // 모바일에서는 50px만 이동
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
  }
`;

const dreamsRow1 = [
  { dream: "비행기를 타고 하늘을 날았어요", interpretation: "새로운 목표를 향한 여정을 시작할 때입니다" },
  { dream: "물속에서 숨을 쉬었어요", interpretation: "감정적인 상황을 잘 다루고 있습니다" },
  { dream: "높은 산을 올랐어요", interpretation: "큰 도전을 앞두고 있지만 성공할 수 있습니다" },
  { dream: "낯선 집에 들어갔어요", interpretation: "자신의 내면을 탐구할 시기입니다" },
  { dream: "꽃밭에서 걸었어요", interpretation: "평화와 행복이 당신을 기다리고 있습니다" },
];

const dreamsRow2 = [
  { dream: "무지개를 만졌어요", interpretation: "희망과 새로운 기회가 다가오고 있습니다" },
  { dream: "거울이 깨졌어요", interpretation: "변화의 시기가 왔습니다. 두려워하지 마세요" },
  { dream: "시험을 봤어요", interpretation: "자신의 능력을 시험받는 상황이 올 수 있습니다" },
  { dream: "별이 떨어졌어요", interpretation: "소원이 이루어질 수 있는 좋은 시기입니다" },
  { dream: "폭풍우 속에 있었어요", interpretation: "어려움을 극복하고 더 강해질 것입니다" },
];

const DreamBackground = () => {
  return (
    <BackgroundContainer>
      <ImageContainer>
        <img src="/mongpick-logo-new.jpg" alt="MongPick animation" />
      </ImageContainer>
      <AnimationContainer>
        <DreamRow>
          {[...dreamsRow1, ...dreamsRow1].map((dream, index) => (
            <DreamBox key={index}>
              <DreamTitle>{dream.dream}</DreamTitle>
              {dream.interpretation}
            </DreamBox>
          ))}
        </DreamRow>
        <DreamRow reverse={true}>
          {[...dreamsRow2, ...dreamsRow2].map((dream, index) => (
            <DreamBox key={index}>
              <DreamTitle>{dream.dream}</DreamTitle>
              {dream.interpretation}
            </DreamBox>
          ))}
        </DreamRow>
      </AnimationContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default DreamBackground;