import React from 'react';
import styled from 'styled-components';

const AdWrapper = styled.div`
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
`;

const AdIframe = styled.iframe`
  border: none;
  width: 500px;
  height: 300px;
  max-width: 100%;
`;

const DisclaimerText = styled.p`
  font-size: 11px;
  color: #666;
  text-align: center;
  margin-top: 10px;
  font-style: italic;
`;

const CoupangAd = () => {
  const iframeUrl = "https://ads-partners.coupang.com/widgets.html?id=815934&template=carousel&trackingCode=AF0991259&subId=&width=500&height=300&tsource=";

  return (
    <AdWrapper>
      <AdIframe
        src={iframeUrl}
        width="450"
        height="300"
        frameBorder="0"
        scrolling="no"
        referrerPolicy="unsafe-url"
        allow="browsingtopics"
      />
      <DisclaimerText>
        이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.
      </DisclaimerText>
    </AdWrapper>
  );
};

export default CoupangAd;