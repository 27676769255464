import React from 'react';
import styled from 'styled-components';
import { CommonContainer } from './CommonStyles';

const LoadingContainer = styled(CommonContainer).attrs({ as: 'div' })`
  justify-content: center;
`;

const LoadingText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingScreen = () => (
  <LoadingContainer>
    <LoadingText>신비로운 꿈이군요</LoadingText>
    <Spinner />
  </LoadingContainer>
);

export default LoadingScreen;