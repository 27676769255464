import styled from 'styled-components';


export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10vh;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding-top: 10vh;
  }

  @media (max-height: 600px) {
    padding-top: 5vh;
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 40px; // 모바일 화면에서 글자 크기 줄임
  }
`;

export const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #666;

  @media (max-width: 768px) {
    font-size: 18px; // 모바일 화면에서 글자 크기 줄임
    margin-bottom: 15px;
  }
`;

export const ErrorMessage = styled.div`
  color: #d32f2f;
  background-color: #ffcdd2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
`;

export const BlogLink = styled.a`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  z-index: 1000;

  &:hover {
    color: #333;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    font-size: 16px;
    top: 15px;
    right: 15px;
  }
`;