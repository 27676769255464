import IntroductionPost from './introduction';

// 블로그 포스트 메타데이터
export const blogPosts = {
  introduction: {
    id: 'introduction',
    title: '꿈과 현실의 연결고리, 몽픽(MongPick)에서 찾는 특별한 의미',
    excerpt: '우리는 매일 밤 꿈을 꾸며 잠에 듭니다. 때로는 기분 좋은 꿈으로, 때로는 불안한 꿈으로 하루를 시작하게 되는데요...',
    date: '2024.10.27',
    component: IntroductionPost
  }
  // 추후 더 많은 포스트 추가
};

// 포스트 목록을 날짜순으로 정렬하여 반환
export const getSortedPosts = () => {
  return Object.values(blogPosts).sort((a, b) => 
    new Date(b.date) - new Date(a.date)
  );
};